import { Button } from "@chakra-ui/react"
import React from "react"
import { Authenticated2 } from "../../components/auth/Authenticated2"
import { BigMessageSpinner } from "../../components/BigMessageSpinner"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { History } from "../../components/typing-test-2/History"
import Stats from "../../components/typing-test/Stats"
import { useLastRecordings } from "../../useLastRecordings"

function ReplayInner() {
  const { isLoading, recordings, deleteRecording } = useLastRecordings(1)

  if (isLoading || !recordings || recordings.length === 0)
    return <BigMessageSpinner message="Loading history..." />

  const rec = recordings[0]

  return (
    <>
      <Stats
        durationSeconds={rec.durationSeconds}
        typedWords={rec.typedWords}
        wordsToType={rec.wordsToType}
      />
      <History history={rec} />
      <Button onClick={() => deleteRecording(rec.id)}>Delete</Button>
    </>
  )
}

export default function Replay2Page() {
  return (
    <DebugLayout title="Replay History">
      <Authenticated2>
        <ReplayInner />
      </Authenticated2>
    </DebugLayout>
  )
}
